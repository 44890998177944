// extracted by mini-css-extract-plugin
export var ArtistDescription = "Futura2000-module--ArtistDescription--2asrv";
export var ArtistInfos = "Futura2000-module--ArtistInfos--NveTX";
export var ButtonWrapper = "Futura2000-module--ButtonWrapper --XghE3";
export var CardWrapper = "Futura2000-module--CardWrapper--+IkjK";
export var CarrouselWrapper2 = "Futura2000-module--CarrouselWrapper2--I7EXH";
export var Citations = "Futura2000-module--Citations--s7snB";
export var DescriptionWrapper = "Futura2000-module--DescriptionWrapper--i+vVs";
export var ImageWrapper = "Futura2000-module--ImageWrapper--w38O2";
export var LinkWrapper = "Futura2000-module--LinkWrapper--bKV5o";
export var MobileProtrait = "Futura2000-module--MobileProtrait--TLxOB";
export var More = "Futura2000-module--More--6eG6R";
export var MoreButton = "Futura2000-module--MoreButton--Zeo1k";
export var NameWrapper = "Futura2000-module--NameWrapper--sstGx";
export var PdpWrapper = "Futura2000-module--PdpWrapper--mdM72";
export var PhotosWrapper = "Futura2000-module--PhotosWrapper--yfrAe";
export var ProfilWrapper = "Futura2000-module--ProfilWrapper--utfIP";
export var TitleWrapper = "Futura2000-module--TitleWrapper--4OrtR";
export var Wrapper = "Futura2000-module--Wrapper--K2uxR";